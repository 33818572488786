import Draw from 'ol/interaction/Draw';
import { useContext, useEffect, useRef } from 'react'
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import Text from 'ol/style/Text';
import { useDispatch } from 'react-redux';
import Snap from 'ol/interaction/Snap';
import { useState } from 'react';
import { MapContext } from '../../../map-container/map/MapContext';
import { never } from 'ol/events/condition';
import { useAppSelector } from '../../../../../state/hooks';
import { DigiPopup } from '../digi-popup/DigiPopup';
import { setCancelDigiActivate, setDigiPopupVisibility, setCalculatedFields, setFrom } from '../../../../../state/features/digitizationSlice';
import { DrawType, Mode, TransactWfs } from '../TransactWfs';
import { setButtonState } from '../../../../../state/features/buttonStateSlice';
import { ButtonState } from '../../../../shared/button-state/ButtonState';
import Feature from 'ol/Feature';
import calculateResult from './calculateResult';
import { GeomDataHolder } from '../../../panorama-container/easy-pano-imports';
import WKT from 'ol/format/WKT';
import { MultiPolygon } from 'ol/geom';
import { getArea } from 'ol/sphere';
import { setDrawState } from '../../../../../state/features/drawStateSlice';
import { DrawStateType } from '../../../../../util/model/drawState';
import VectorSource from 'ol/source/Vector';

/* 
 * Bu componentle polygon geometri tipinde bir feature oluşrutuyoruz.
 *
*/
interface Props {
  source: any
}

export const DigiDrawPolygon = (props: Props) => {
  const map = useContext(MapContext);
  const dispatch = useDispatch();

  const [feature, setFeature] = useState<any>(undefined)
  const digiPopupVisibility = useAppSelector((state) => state.digitization.digiPopupVisibility)
  const transactStatus = useAppSelector(state => state.digitization.transactStatus)
  const cancelDigiActivate = useAppSelector((state) => state.digitization.cancelDigiActivate)
  const buttonState: any = useAppSelector(state => state.buttonState.buttonState)
  const leftClickCount = useRef(0);
  const from: string = useAppSelector(state => state.digitization.from)

  useEffect(() => {
    const vectorSource = props.source;
    const featureForParallel: any = vectorSource.getFeatures()[0]
    if (featureForParallel) {
      const feature = new Feature()
      feature.setGeometry(featureForParallel.values_.geometry)
      setFeature(feature)
      dispatch(setDigiPopupVisibility(true))
      dispatch(setFrom("Map"))
    }
  }, [])

  useEffect(() => {
    if(digiPopupVisibility){
        const intrcts = (window as any).olMap.getInteractions().getArray()
        intrcts.forEach((interaction: any) => {
            if (interaction instanceof Draw) {
                map.removeInteraction(interaction)
            }
        });
    }
}, [digiPopupVisibility]);

  useEffect(() => {
    let draw = new Draw({
      source: props.source,
      type: 'Polygon',
      style: new Style({
        fill: new Fill({
          color: 'rgb(165, 33, 37, 0.44)',
        }),
        stroke: new Stroke({
          color: 'red',
          width: 2,
        }),
        image: new CircleStyle({
          radius: 5,
          fill: new Fill({
            color: 'red',
          }),
        }),
      }),
      finishCondition: never,
      condition: function (e: any) {
        //sadece solla çizim yapıyor ve sağla bitiriyor

        if (e.originalEvent.buttons === 1) {
          leftClickCount.current += 1;
          return true;
        } else if (e.originalEvent.buttons === 2) {
          if (leftClickCount.current < 3) {
            dispatch(setCancelDigiActivate(true))
            setTimeout(() => {
              props.source.clear();
            }, 1);

            dispatch(setButtonState(ButtonState.NONE));
            dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
            map.removeInteraction(draw)

            leftClickCount.current = 0;
            draw.finishDrawing();
            return false;
          }
          else {
            dispatch(setCancelDigiActivate(false))
            leftClickCount.current = 0;
            draw.finishDrawing();
            return true;
          }
        }
        else {
          return false;
        }
      },
    });
    // (window as any).olMap.getLayers().getArray()
    // .filter((layer2: any) => layer2.getVisible())
    // .map((layer: any) => {
    //     const wms: any = layer;
    //     const _source = wms.getSource()
    //     if (_source instanceof VectorSource) {
    //         const snap = new Snap({
    //             source: _source
    //         });
    //         map.addInteraction(snap);
    //     }
    // })

    map.addInteraction(draw);

    draw.on('drawend', async (event: any) => {
      map.removeInteraction(draw)
      // const polygonArea = event.feature?.getGeometry().getArea()

      const _geom = event.feature?.getGeometry().clone();
      const label = getArea(_geom)//.toFixed(2) + 'm';

      setTextAfterDrawEnd(event, label)

      // setTextAfterDrawEnd(event, polygonArea)
      setFeature(event.feature)

      let geom;
      const wkt = new WKT();

      const _geomClone: any = event.feature.getGeometry().clone()
      geom = new MultiPolygon([_geomClone.transform('EPSG:3857', 'EPSG:4326').getCoordinates()]);
      const geomWktLine = wkt.writeGeometry(geom);
      geom.transform('EPSG:4326', 'EPSG:3857');
      const gdh: any = GeomDataHolder.fromWKT(geomWktLine);
      (window as any).panogl.getScalable().getMainSketchLayer().addGlobal(gdh);
      (window as any).panogl.getScalable().getMainSketchLayer().refresh();
      const calcFields = await calculateResult(event.feature);
      dispatch(setCalculatedFields(calcFields));


    });
    const content = map.getTargetElement();
    content.addEventListener('contextmenu', (event: MouseEvent) => {
      map.removeInteraction(draw);
    });

    return () => {
      map.removeInteraction(draw)
      props.source.clear();
    }

  }, [map])


  /**
   * Çizim bittikten sonra ölçüm değerini çizilen nesnenin üzerine yazar. 
   */
  const setTextAfterDrawEnd = (event: any, polygonArea: any) => {
    dispatch(setDigiPopupVisibility(true))
    dispatch(setFrom("Map"))
    polygonArea = polygonArea.toFixed(2);

    event.feature.setStyle(new Style({
      fill: new Fill({
        color: 'rgb(165, 33, 37, 0.44)',
      }),
      stroke: new Stroke({
        color: 'red',
        width: 2,
      }),
      image: new CircleStyle({
        radius: 5,
        fill: new Fill({
          color: 'red',
        }),
      }),
      text: new Text({
        text: `${polygonArea}m`,
        font: "bold 15px 'Open Sans', 'Arial Unicode MS', 'sans-serif'",
        placement: 'point',
        offsetY: -15,
        fill: new Fill({
          color: 'white',
        }),
        stroke: new Stroke({
          color: 'black',
          width: 3,
        }),
      })

    }));
  }

  useEffect(() => {
    if (cancelDigiActivate) {
      props.source.clear();
      map.updateSize();
      setFeature(undefined)
      dispatch(setCancelDigiActivate(false));
      // dispatch(setButtonState(ButtonState.NONE))
      const layer_:any = map.getLayers().getArray().find((el:any)=> el.getProperties().alias === 'draws')
      const draws:any = [...layer_.getSource().getFeatures().filter((el:any)=>el.get('alias') !== 'draws')]
      if(draws.length){
          layer_.getSource().clear();
          layer_.getSource().addFeatures(draws)
      }else{
          layer_.getSource().clear();
      }
    }
  }, [cancelDigiActivate])

  return (
    <>
      {digiPopupVisibility && from !== 'Panorama' && <DigiPopup mode={Mode.INSERT} title='Add New Feature' from='Map' />}
      {feature && feature?.getGeometry() && transactStatus && (buttonState === ButtonState.ADD || ((window as any).buttonState === ButtonState.ADD && (window as any).drawStartOfDigi) || buttonState === ButtonState.PARALLEL) && <TransactWfs coordinates={feature.getGeometry().getCoordinates()} feature={feature} mode={Mode.INSERT} source={props.source} type={DrawType.POLYGON} />}
    </>
  )
}

