import Draw, { DrawEvent } from 'ol/interaction/Draw';
import { useContext, useEffect, useState } from 'react'
import { MapContext } from '../MapContext'
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import Text from 'ol/style/Text';
import { never } from 'ol/events/condition';
import { setDrawState } from '../../../../../state/features/drawStateSlice';
import { DrawStateType } from '../../../../../util/model/drawState';
import { useDispatch } from 'react-redux';
import { WKT } from 'ol/format';
import { setMapCoordinates } from '../../../../../state/features/coordinatesSlice';
import { getArea } from 'ol/sphere';
import { setButtonState } from '../../../../../state/features/buttonStateSlice';
import { ButtonState } from '../../../../shared/button-state/ButtonState';
interface Props {
    source: any
}

export const DrawPolygon = (props: Props) => {
    const map = useContext(MapContext);
    const dispatch = useDispatch();
    const [labeltext, setLabel] = useState<any>()

    useEffect(() => {

        let draw = new Draw({
            source: props.source,
            type: 'Polygon',
            stopClick: true,
            style: new Style({
                fill: new Fill({
                    color: '#64b5f640',
                }),
                stroke: new Stroke({
                    color: '#3ea1f1',
                    width: 3,
                }),
                image: new CircleStyle({
                    radius: 5,
                    fill: new Fill({
                        color: '#3ea1f1',
                    }),
                }),
            }),
            finishCondition: never,
            condition: function (e: any) {
                //sadece solla çizim yapıyor ve sağla bitiriyor
                if (e.originalEvent.buttons === 1) {
                    return true;
                } else if (e.originalEvent.buttons === 2) {
                    if (!labeltext) {
                        if(!((window as any).buttonState === ButtonState.ADD && (window as any).drawStartOfDigi)){
                            dispatch(setButtonState(ButtonState.NONE));
                        }
                        dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
                    }
                    draw.finishDrawing();


                    return false;
                }
                else {
                    return false;
                }
            }
        });

        map.addInteraction(draw);

        drawEnd(draw);

        return () => {
            map.removeInteraction(draw)
        }

    }, [map])

    const drawEnd = (draw: Draw) => {
        draw.on('drawend', (event: any) => {
            const _geom = event.feature?.getGeometry().clone();
            const label = getArea(_geom)//.toFixed(2) + 'm';

            setLabel(label)
            if (label) {
                setTextAfterDrawEnd(event, label)
            }
        });
    }

    /**
     * Çizim bittikten sonra ölçüm değerini çizilen nesnenin üzerine yazar. 
     */
    const setTextAfterDrawEnd = (event: DrawEvent, polygonArea: any) => {
        const feature = event.feature;
        polygonArea = polygonArea.toFixed(2);

        event.feature.setStyle(new Style({
            fill: new Fill({
                color: '#64b5f640',
            }),
            stroke: new Stroke({
                color: '#3ea1f1',
                width: 4,
            }),
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: '#3ea1f1',
                }),
            }),
            text: new Text({
                text: `${polygonArea}m²`,
                overflow: true,
                font: "bold 15px 'Open Sans', 'Arial Unicode MS', 'sans-serif'",
                placement: 'point',
                offsetY: -15,
                fill: new Fill({
                    color: 'white',
                }),
                stroke: new Stroke({
                    color: 'black',
                    width: 3,
                }),
                rotateWithView: true
            })
        }));

        //aynı çizimi panorama üzerinde de göstermek için
        const wkt = new WKT();
        const wktFeature = wkt.writeFeature(feature)

        const result = {
            type: 'Point',
            feature: wktFeature,
            text: `${polygonArea}m`
        }

        dispatch(setMapCoordinates(result))
    }
    return (
        <></>
    )
}
