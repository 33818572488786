import { PropsWithChildren, useContext, useEffect, useRef } from 'react'
import { MapContext } from '../MapContext'
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import { TileLayerContext } from './TileLayerContext';
import { TileWMS } from 'ol/source';
interface Props {
    url: string;
    visible: boolean;
    layername?: string;
    opacity?: number;
}
export const Tile = (props: PropsWithChildren<Props>) => {
    const map = useContext(MapContext)
    const t = useRef<TileLayer<any>>(new TileLayer())
    useEffect(() => {
        const tileLayer = t.current!;
        if (!props.layername) {
            tileLayer.setSource(new XYZ({
                url: props.url,
                crossOrigin: "anonymous"
            }));
            tileLayer.setVisible(props.visible);
            tileLayer.setZIndex(-1)
        }
        else {
            tileLayer.setSource(new TileWMS({
                url: props.url,
                params: { 'LAYERS': `${props.layername}`, 'TILED': true },
                serverType: 'geoserver',
                crossOrigin: "anonymous"
            }))
            tileLayer.setZIndex(10)
            tileLayer.setVisible(props.visible)
        }
        map.addLayer(tileLayer);
        return () => {
            map.removeLayer(tileLayer);
        }
    }, []);
    useEffect(() => {
        const tileLayer = t.current!;
        if (props.opacity) {
            tileLayer.setVisible(true)
            tileLayer.setOpacity(props.opacity / 100)
        }
    }, [props.opacity])
    return <TileLayerContext.Provider value={t.current}>{props.children}</TileLayerContext.Provider>;
}