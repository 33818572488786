import WFS from 'ol/format/WFS';
import GML from 'ol/format/GML';
import MultiPolygon from 'ol/geom/MultiPolygon';
import MultiPoint from 'ol/geom/MultiPoint';
import MultiLineString from 'ol/geom/MultiLineString';
import Feature from 'ol/Feature';
import { useDispatch } from 'react-redux';
import { useContext, useEffect } from 'react';
import { DigiCallbackContext } from './DigiCallbackContextProvider';
import { GeoJSON } from 'ol/format';
import { MapContext } from '../../map-container/map/MapContext';
import { useAppSelector } from '../../../../state/hooks';
import { DigitizationRestApi } from '../../../../util/restapi/digitization';
import { setDigiPopupVisibility, setGeomEditStatus, setPanoramaTransactStatus, setPopupSaveStatus, setTransactStatus } from '../../../../state/features/digitizationSlice';
import { FeatureRestApi } from '../../../../util/restapi/features';
import { setFeatures } from '../../../../state/features/tableSlice';
import { setSelectedFeature, setVertexFeature } from '../../../../state/features/selectedFeatureSlice';
import { DrawStateType } from '../../../../util/model/drawState';
import * as XmlBuilder from 'xmlbuilder';
import { ButtonState } from '../../../shared/button-state/ButtonState';
import { setButtonState } from '../../../../state/features/buttonStateSlice';

/* 
 * Sayısallaştırma işlemlerinin (write,delete,update) yapıldığı component.
 *
*/

export enum Operation {
    INSERT = 'Insert',
    UPDATE = 'Update',
    DELETE = 'Delete',
}

export enum Mode {
    INSERT = 'INSERT',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE'
}

export enum DrawType {
    POINT = 'Point',
    LINESTRING = 'LineString',
    POLYGON = 'Polygon'
}

export enum GeometryType {
    POINT = 'Point',
    LINESTRING = 'LineString',
    LINE = 'Line',
    POLYGON = 'Polygon',
    MULTIPOINT = 'MultiPoint',
    MULTILINESTRING = 'MultiLineString',
    MULTIPOLYGON = 'MultiPolygon',
}


interface Props {
    feature?: any;
    mode: Mode;
    source?: any;
    type: any;
    coordinates?: any;
    finish?: any
}

export enum SrsDimension {
    TWO_DIMENSION = '2',
    THREE_DIMENSION = '3',
}


export const TransactWfs = (props: Props) => {
    const dispatch = useDispatch();
    const map = useContext(MapContext);
    const digiCallback = useContext(DigiCallbackContext);
    const changedFields: any = useAppSelector(state => state.digitization.changedFields)
    const editedProperties: any = useAppSelector(state => state.digitization.editedProperties)
    const selectedLayer: any = useAppSelector(state => state.layer.selectedLayer)
    const services: any = useAppSelector(state => state.layer.services);
    const selectedFeature: any = useAppSelector(state => state.selectedFeature.feature);
    const geoserverUrl: any = useAppSelector(state => state.layer.geoserverUrl);
    const workspace: any = useAppSelector(state => state.layer.workspace);
    const buttonState: any = useAppSelector(state => state.buttonState.buttonState)
    const calcFields: any = useAppSelector(state => state.digitization.calculatedFields);

    const keyOfTypes: any = {
        "Length": "length",
        "Start_Point": "start_Point",
        "End_Point": "End_Point",
        "Middle_Point": "Middle_Point",
        "Area": "Area",
        "Location": "location",
        "Start_Point(X)": "start_Point_X",
        "End_Point(X)": "End_Point_X",
        "Middle_Point(X)": "Middle_Point_X",
        "Location(X)": "locationX",
        "Start_Point(Y)": "start_Point_Y",
        "End_Point(Y)": "End_Point_Y",
        "Middle_Point(Y)": "Middle_Point_Y",
        "Location(Y)": "locationY",
        "Start_Point(Z)": "start_Point_Z",
        "End_Point(Z)": "End_Point_Z",
        "Middle_Point(Z)": "Middle_Point_Z",
        "Location(Z)": "locationZ",
        // "Height": 'Height',
        "Perimeter": 'perimeter'
    }
    let optionMove: any;
    const getLayerAndService = (featureId: string, services: any) => {
        let service;
        let layer;
        const layerName = selectedLayer.name//featureId.split('.')[0]

        for (const item of services) {
            for (const testLayer of item.layers) {
                if (testLayer.name === layerName) {
                    layer = testLayer
                    service = item
                }
            }
        }
        const obj = {
            layer: layer,
            service: service
        }
        return obj
    }

    useEffect(() => {
        let formatWFS = new WFS();
        let formatGML = new GML({
            featureNS: workspace,
            featureType: selectedLayer.name,
            srsName: 'EPSG:3857',
            hasZ: true,

        });

        let xs = new XMLSerializer();

        let node: any;


        const options: any = {
            operation: Operation.INSERT,
            typeName: selectedLayer.name,
            workspace: workspace,
            geometryName: 'geom',
            srsName: 'EPSG:3857',
            srsDimension: '3'
        }
        if (buttonState === ButtonState.MOVE) {
            optionMove = getLayerAndService(props.feature.id, services)
            options.typeName = optionMove.layer.name;
            options.workspace = optionMove.service.workspace;
        } else if (buttonState === ButtonState.PARALLEL) {
            optionMove = getLayerAndService(selectedFeature[0].id, services)
            options.typeName = optionMove.layer.name;
            options.workspace = optionMove.service.workspace;
        }
        let xml;
        switch (props.mode) {
            case 'INSERT':
                xml = insertXmlGenerator(options, props.feature);
                //@ts-ignore
                // node = formatWFS.writeTransaction([insertFeature], null, null, formatGML);
                break;
            case 'UPDATE':
                if (props.feature) {
                    //burası vertex edit için
                    // const editedFeature = props.feature
                    const editedFeature: any = new GeoJSON().readFeatures(props.feature)[0];
                    // editedFeature.setGeometryName('geom')
                    editedFeature.setProperties(editedProperties);
                    // editedFeature.unset('bbox', true)
                    // editedFeature.unset('key', true)
                    // editedFeature.unset('label', true)
                    const geoJsonFeature = new GeoJSON().writeFeaturesObject([editedFeature]).features[0];
                    xml = updateXmlGenerator(options, geoJsonFeature);
                    //@ts-ignore
                    // node = formatWFS.writeTransaction(null, [editedFeature], null, formatGML);
                }
                break;
            case 'DELETE':
                for (const feature of selectedFeature) {
                    const deleteFeature = new GeoJSON().readFeature(feature)
                    xml = deleteXmlGenerator(options, feature);
                    //@ts-ignore
                    // node = formatWFS.writeTransaction(null, null, [deleteFeature], formatGML);
                }
                // const deleteFeature = deleteOperation();
                break;
        }
        let payload = xml
        DigitizationRestApi.digiAddFeature(payload, ((buttonState === ButtonState.MOVE || buttonState === ButtonState.PARALLEL) ? optionMove.service.url : geoserverUrl)).then((res: any) => {
            if (res) {
                // dispatch(setToastMessageStatus(true))
                dispatch(setPanoramaTransactStatus(false))
                dispatch(setDigiPopupVisibility(false))
                if (props.finish) {
                    props.finish()
                }
            }
            // insert yapiliyorsa geoservera istek yapip featureId yi alacak o id ile attachment istegi yapacak
            if (props.mode === 'INSERT' || props.mode === 'UPDATE') {
                const featureId = res.split('.')[1]
                // if (typeof (window as any).addDigiCallback === 'function') {
                //     (window as any).addDigiCallback(featureId)
                // }
                if (typeof digiCallback.callbackFunction === 'function') {
                    digiCallback.callbackFunction(featureId)
                }
            }
        }).catch((err: any) => {
        })

        const url = services.find((service: any) => service.id === selectedLayer.service_id)?.url

        // FeatureRestApi.getFeatures(url, selectedLayer.name).then((res: any) => {
        //     // console.log("  FeatureRestApi.getFeatures(url,");

        //     if (res) {
                // dispatch(setFeatures(res.data.features));

                // map update 
                const layersOfMap = (window as any).olMap.getLayers();
                layersOfMap.forEach((layer: any) => {
                    let source: any = layer.getSource();
                    if (source.updateParams != undefined)
                        source.updateParams({ 'time': Date.now() });
                });
                dispatch(setPanoramaTransactStatus(undefined))
                dispatch(setButtonState(ButtonState.NONE));

                (window as any).olMap.updateSize();
                (window as any).olMap.render();
                // ? props.source?.clear()
        //     }
        // })
        props.source?.clear()
        return () => {
            dispatch(setPanoramaTransactStatus(undefined))
            dispatch(setTransactStatus(undefined))
            dispatch(setPopupSaveStatus(undefined))
            dispatch(setSelectedFeature([]))
            dispatch(setVertexFeature([]))
            dispatch(setGeomEditStatus(false))
            dispatch(setDigiPopupVisibility(false));
            (window as any).olMap.updateSize();
            (window as any).olMap.render();
        }
    }, [props.feature])

    const insertXmlGenerator = (options: any, feature: any) => {
        if (props.mode === 'INSERT' && props.type) {
            let newFeature = feature

            let propertyOfFeatures: any[] = []
            // console.log("INSERT ****************************** : ",changedFields );

            changedFields.map((field: any) => {
                if (field.inputValue) {
                    propertyOfFeatures.push({ [field.name]: field.inputValue })
                } else {
                    if (calcFields.hasOwnProperty(keyOfTypes[field.type])) {
                        propertyOfFeatures.push({ [field.name]: calcFields[keyOfTypes[field.type]] })
                    }
                }
            })
            console.log("propertyOfFeatures :", propertyOfFeatures);

            const properties = Object.assign({}, ...propertyOfFeatures);
            newFeature.setProperties(properties)
            newFeature.setGeometryName('geom')

            const geometryType = props.type

            let geometryCoord;
            if (buttonState === ButtonState.PARALLEL && (geometryType === 'Point' || geometryType === 'Polygon' || geometryType === 'LineString') && props.coordinates.length === 1) {
                geometryCoord = props.coordinates[0]
            } else {
                geometryCoord = props.coordinates
            }
            const joinedCoordinates = joinedCoordinatesGenerator(geometryType, geometryCoord, options.srsDimension);

            const xml = XmlBuilder.create('Transaction', { encoding: 'utf-8' })
                .att({
                    xmlns: 'http://www.opengis.net/wfs',
                    service: 'WFS',
                    version: '1.1.0',
                    'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
                    'xsi:schemaLocation': 'http://www.opengis.net/wfs http://schemas.opengis.net/wfs/1.1.0/wfs.xsd'
                })
                .ele(Operation.INSERT)
                .ele(options.typeName, { xmlns: options.workspace });

            for (const property of Object.keys(properties)) {
                if (properties[property] !== undefined) {
                    xml.ele(property, properties[property]);
                }
            }
            switch (geometryType) {
                case GeometryType.POINT:

                    xml.ele(options.geometryName)
                        .ele(GeometryType.MULTIPOINT, { xmlns: 'http://www.opengis.net/gml', srsName: options.srsName })
                        .ele('pointMember')
                        .ele('Point', { srsName: options.srsName })
                        .ele('pos', { srsDimension: options.srsDimension }, joinedCoordinates);
                    break;
                case GeometryType.LINESTRING:
                    xml.ele(options.geometryName)
                        .ele(GeometryType.MULTILINESTRING, { xmlns: 'http://www.opengis.net/gml', srsName: options.srsName })
                        .ele('lineStringMember')
                        .ele('LineString', { srsName: options.srsName })
                        .ele('posList', { srsDimension: options.srsDimension }, joinedCoordinates);
                    break;
                case GeometryType.LINE:
                    xml.ele(options.geometryName)
                        .ele(GeometryType.MULTILINESTRING, { xmlns: 'http://www.opengis.net/gml', srsName: options.srsName })
                        .ele('lineStringMember')
                        .ele('LineString', { srsName: options.srsName })
                        .ele('posList', { srsDimension: options.srsDimension }, joinedCoordinates);
                    break;
                case GeometryType.POLYGON:
                    xml.ele(options.geometryName)
                        .ele(GeometryType.MULTIPOLYGON, { xmlns: 'http://www.opengis.net/gml', srsName: options.srsName })
                        .ele('polygonMember')
                        .ele('Polygon', { srsName: options.srsName })
                        .ele('exterior')
                        .ele('LinearRing', { srsName: options.srsName })
                        .ele('posList', { srsDimension: options.srsDimension }, joinedCoordinates);
                    break;
                case GeometryType.MULTIPOINT:
                    xml.ele(options.geometryName)
                        .ele(GeometryType.MULTIPOINT, { xmlns: 'http://www.opengis.net/gml', srsName: options.srsName })
                        .ele('pointMember')
                        .ele('Point', { srsName: options.srsName })
                        .ele('pos', { srsDimension: options.srsDimension }, joinedCoordinates);
                    break;
                case GeometryType.MULTILINESTRING:
                    xml.ele(options.geometryName)
                        .ele(GeometryType.MULTILINESTRING, { xmlns: 'http://www.opengis.net/gml', srsName: options.srsName })
                        .ele('lineStringMember')
                        .ele('LineString', { srsName: options.srsName })
                        .ele('posList', { srsDimension: options.srsDimension }, joinedCoordinates);
                    break;
                case GeometryType.MULTIPOLYGON:
                    xml.ele(options.geometryName)
                        .ele(GeometryType.MULTIPOLYGON, { xmlns: 'http://www.opengis.net/gml', srsName: options.srsName })
                        .ele('polygonMember')
                        .ele('Polygon', { srsName: options.srsName })
                        .ele('exterior')
                        .ele('LinearRing', { srsName: options.srsName })
                        .ele('posList', { srsDimension: options.srsDimension }, joinedCoordinates);
                    break;
            }
            return xml.end({ pretty: true });

            // if (props.type === 'POINT') {
            //     const point = new MultiPoint([])
            //     point.appendPoint(props.feature.getGeometry())
            //     newFeature.setGeometry(point)
            //     //newFeature.setGeometry(new Point(props.feature.getGeometry().getCoordinates()))
            // }
            // else if (props.type === 'LINESTRING') {
            //     const linestring = new MultiLineString([]);

            //     linestring.appendLineString(props.feature.getGeometry())
            //     newFeature.setGeometry(linestring)
            // }
            // else if (props.type === 'POLYGON') {
            //     const polygon = new MultiPolygon([]);
            //     polygon.appendPolygon(props.feature.getGeometry())
            //     newFeature.setGeometry(polygon)
            // }
            // console.log(newFeature);

            return newFeature
        }
    }


    const updateXmlGenerator = (options: any, geoJsonFeature: any) => {

        // console.log("updateXmlGenerator ********************  : ",calcFields);

        let propertyOfFeatures: any[] = []
        changedFields.map((field: any) => {
            if (field.inputValue) {
                propertyOfFeatures.push({ [field.name]: field.inputValue })
            } else {
                if (calcFields.hasOwnProperty(keyOfTypes[field.type])) {
                    propertyOfFeatures.push({ [field.name]: calcFields[keyOfTypes[field.type]] })
                }
            }
        })
        // console.log("propertyOfFeatures :",propertyOfFeatures);

        const properties = Object.assign({}, ...propertyOfFeatures);

        const geometryType = props.type
        const geometryCoord = props.coordinates

        const joinedCoordinates = joinedCoordinatesGenerator(geometryType, geometryCoord, options.srsDimension);

        const xml = XmlBuilder.create('Transaction', { encoding: 'utf-8' })
            .att({
                xmlns: 'http://www.opengis.net/wfs',
                service: 'WFS',
                version: '1.1.0',
                'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
                'xsi:schemaLocation': 'http://www.opengis.net/wfs http://schemas.opengis.net/wfs/1.1.0/wfs.xsd'
            })
            .ele(Operation.UPDATE, { typeName: 'feature:' + options.typeName, 'xmlns:feature': options.workspace });
        // const properties = geoJsonFeature.properties
        const featureId = geoJsonFeature.properties.id
        for (const property of Object.keys(properties)) {
            if (properties[property] !== undefined) {
                xml.ele('Property')
                    .ele('Name', property).up()
                    .ele('Value', properties[property]);
            }
        }

        switch (geometryType) {
            case GeometryType.POINT:
                xml.ele('Property')
                    .ele('Name', {}, options.geometryName).up()
                    .ele('Value')
                    .ele(GeometryType.POINT, { xmlns: 'http://www.opengis.net/gml', srsName: options.srsName })
                    .ele('pos', { srsDimension: options.srsDimension }, joinedCoordinates);
                break;
            case GeometryType.LINESTRING:
                xml.ele('Property')
                    .ele('Name', {}, options.geometryName).up()
                    .ele('Value')
                    .ele(GeometryType.MULTILINESTRING, { xmlns: 'http://www.opengis.net/gml', srsName: options.srsName })
                    .ele('lineStringMember')
                    .ele('LineString', { srsName: options.srsName })
                    .ele('posList', { srsDimension: options.srsDimension }, joinedCoordinates);
                break;
            case GeometryType.POLYGON:
                xml.ele('Property')
                    .ele('Name', {}, options.geometryName).up()
                    .ele('Value')
                    .ele(GeometryType.MULTIPOLYGON, { xmlns: 'http://www.opengis.net/gml', srsName: options.srsName })
                    .ele('polygonMember')
                    .ele('Polygon', { srsName: options.srsName })
                    .ele('exterior')
                    .ele('LinearRing', { srsName: options.srsName })
                    .ele('posList', { srsDimension: options.srsDimension }, joinedCoordinates);
                break;
            case GeometryType.MULTIPOINT:
                xml.ele('Property')
                    .ele('Name', {}, options.geometryName).up()
                    .ele('Value')
                    .ele(GeometryType.MULTIPOINT, { xmlns: 'http://www.opengis.net/gml', srsName: options.srsName })
                    .ele('pointMember')
                    .ele('Point', { srsName: options.srsName })
                    .ele('pos', { srsDimension: options.srsDimension }, joinedCoordinates);
                break;
            case GeometryType.MULTILINESTRING:
                xml.ele('Property')
                    .ele('Name', {}, options.geometryName).up()
                    .ele('Value')
                    .ele(GeometryType.MULTILINESTRING, { xmlns: 'http://www.opengis.net/gml', srsName: options.srsName })
                    .ele('lineStringMember')
                    .ele('LineString', { srsName: options.srsName })
                    .ele('posList', { srsDimension: options.srsDimension }, joinedCoordinates);
                break;
            case GeometryType.LINE:
                xml.ele('Property')
                    .ele('Name', {}, options.geometryName).up()
                    .ele('Value')
                    .ele(GeometryType.MULTILINESTRING, { xmlns: 'http://www.opengis.net/gml', srsName: options.srsName })
                    .ele('lineStringMember')
                    .ele('LineString', { srsName: options.srsName })
                    .ele('posList', { srsDimension: options.srsDimension }, joinedCoordinates);
                break;
            case GeometryType.MULTIPOLYGON:
                xml.ele('Property')
                    .ele('Name', {}, options.geometryName).up()
                    .ele('Value')
                    .ele(GeometryType.MULTIPOLYGON, { xmlns: 'http://www.opengis.net/gml', srsName: options.srsName })
                    .ele('polygonMember')
                    .ele('Polygon', { srsName: options.srsName })
                    .ele('exterior')
                    .ele('LinearRing', { srsName: options.srsName })
                    .ele('posList', { srsDimension: options.srsDimension }, joinedCoordinates);
                break;
        }

        xml.ele('Filter', { xmlns: 'http://www.opengis.net/ogc' })
            .ele('FeatureId', { fid: featureId });

        return xml.end({ pretty: true });
    }




    const deleteXmlGenerator = (options: any, feature: any) => {
        const xml = XmlBuilder.create('Transaction', { encoding: 'utf-8' })
            .att({
                xmlns: 'http://www.opengis.net/wfs',
                service: 'WFS',
                version: '1.1.0',
                'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
                'xsi:schemaLocation': 'http://www.opengis.net/wfs http://schemas.opengis.net/wfs/1.1.0/wfs.xsd'
            })
            .ele(Operation.DELETE, { typeName: 'feature:' + options.typeName, 'xmlns:feature': options.workspace })
            .ele('Filter', { xmlns: 'http://www.opengis.net/ogc' })
            .ele('FeatureId', { fid: feature.properties.id });

        return xml.end({ pretty: true });
    }

    const joinedCoordinatesGenerator = (geometryType: any, geometryCoord: any, srsDimension: SrsDimension) => {
        const coordinates = [];
        switch (geometryType) {
            case GeometryType.POINT:
                if (srsDimension === SrsDimension.TWO_DIMENSION) {
                    coordinates.push(geometryCoord[0] + ' ' + geometryCoord[1]);
                }
                else {

                    if (geometryCoord.length === 2) {
                        coordinates.push(geometryCoord.join(' ') + ' ' + 0);
                    } else {
                        coordinates.push(geometryCoord.join(' '));
                    }
                }

                break;
            case GeometryType.MULTIPOINT:
            case GeometryType.LINESTRING:
                if (srsDimension === SrsDimension.TWO_DIMENSION) {
                    for (const point of geometryCoord) {
                        coordinates.push(point[0] + ' ' + point[1]);
                    }
                } else {
                    for (const point of geometryCoord) {
                        if (point.length === 2) {
                            coordinates.push(point.join(' ') + ' ' + 0);
                        } else {
                            coordinates.push(point.join(' '));
                        }
                    }
                }
                break;
            case GeometryType.LINE:
                if (srsDimension === SrsDimension.TWO_DIMENSION) {
                    for (const point of geometryCoord) {
                        coordinates.push(point[0] + ' ' + point[1]);
                    }
                } else {
                    for (const point of geometryCoord) {
                        if (point.length === 2) {
                            coordinates.push(point.join(' ') + ' ' + 0);
                        } else {
                            coordinates.push(point.join(' '));
                        }
                    }
                }
                break;
            case GeometryType.MULTILINESTRING:
            case GeometryType.POLYGON:
                if (srsDimension === SrsDimension.TWO_DIMENSION) {
                    for (const line of geometryCoord) {
                        for (const point of line) {
                            coordinates.push(point[0] + ' ' + point[1]);
                        }
                    }
                } else {
                    for (const line of geometryCoord) {
                        for (const point of line) {
                            if (point.length === 2) {
                                coordinates.push(point.join(' ') + ' ' + 0);
                            } else {
                                coordinates.push(point.join(' '));
                            }
                        }
                    }
                }
                break;
            case GeometryType.MULTIPOLYGON:
                if (srsDimension === SrsDimension.TWO_DIMENSION) {
                    for (const polygon of geometryCoord) {
                        for (const line of polygon) {
                            for (const point of line) {
                                coordinates.push(point[0] + ' ' + point[1]);
                            }
                        }
                    }
                } else {
                    for (const polygon of geometryCoord) {
                        for (const line of polygon) {
                            for (const point of line) {
                                if (point.length === 2) {
                                    coordinates.push(point.join(' ') + ' ' + 0);
                                } else {
                                    coordinates.push(point.join(' '));
                                }
                            }
                        }
                    }
                }
                break;
        }

        return coordinates.join(' ');
    }



    return (
        <>
        </>
    )
}
