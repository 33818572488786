import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebarWidth: 0
}
export const styleControlSlice = createSlice({
  name: "styleControl",
  initialState,
  reducers: {
    setSidebarWidth: (state: any, action) => {
      state.sidebarWidth = action.payload
    }
  },
});

export const {
  setSidebarWidth
} = styleControlSlice.actions
export default styleControlSlice.reducer;