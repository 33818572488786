import './Footer.scss'
import Moment from 'moment';
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../state/hooks'
import { Dropdown } from 'primereact/dropdown';
import { useEffect, useRef, useState } from 'react';
import { setPanoramaCoordinate, setPointerCoordinate, setSelectedProjection } from '../../../state/features/panoramaSlice';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Point } from 'ol/geom';
import { Toast } from 'primereact/toast';
import { LayerRestApi } from '../../../util/restapi/layer';
import proj4 from 'proj4';
//@ts-ignore
import proj4List from 'proj4-list';

export const Footer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [coordinates, setCoordinates] = useState<any>();
  const [formats, setFormats] = useState<any>();
  const [epsgDefs, setEpsgDefs] = useState<any>();
  const [selectedFormat, setSelectedFormat] = useState<any>();
  const panoramaInfo = useAppSelector(state => state.panorama.info)
  const pointerCoordinate = useAppSelector(state => state.panorama.pointerCoordinate);
  const isFullScreen = useAppSelector(state => state.panorama.isFullScreen);
  const isPanoMiniScreen = useAppSelector(state => state.panorama.isPanoMiniScreen);
  const tableVisibility: any = useAppSelector(state => state.buttonState.tableButton);
  const toast = useRef<any>(null)

  useEffect(() => {
    getEpsgList()
  }, [])

  const getEpsgList = async () => {
    try {
      const response = await LayerRestApi.getEpsgList();

      const epsgForProj = response.data.map((item: any) => {
        return proj4List[item];
      }).filter((element: any) => element && element[1] !== 'undefined');
      const epsg = epsgForProj.map((item: any) => {
        return { coordinateFormat: item[0] };
      });
      setFormats(epsg)
      setSelectedFormat({ coordinateFormat: 'EPSG:4326' })
    } catch (error) {
    }
  }

  const onSelectedFormatChanged = (e: any) => {
    if (e.value.coordinateFormat) {
      const value = e.value.coordinateFormat;
      if (pointerCoordinate && selectedFormat.coordinateFormat && value) {
        /* if (epsgDefs) {
           epsgDefs.find((def: any) => console.log(def)
           )
         }*/
         // proj4e koordinat eklemek için önce epsgkodu sonra pro4 tanımı ile defs fonksiyonu çalıştırılmalı.
         proj4.defs(proj4List[value][0],proj4List[value][1]);
         const transformed = proj4(selectedFormat.coordinateFormat, value, pointerCoordinate);
        if (typeof pointerCoordinate[0] === 'number' && typeof pointerCoordinate[1] === 'number') {
          const coordinate = value === 'EPSG:4326' ? [+transformed[0].toFixed(6), +transformed[1].toFixed(6)] : [+transformed[0].toFixed(4), +transformed[1].toFixed(4)];
          dispatch(setPointerCoordinate(coordinate));
          setSelectedFormat(e.value);
          dispatch(setSelectedProjection(value));
        }
      }
    }
  };
  const goLocation = () => {
    if (coordinates) {
      const replacedCoordinateValue = coordinates.replace(/ /g, '');

      const coordinateStringArray = replacedCoordinateValue.split(',');

      const coordinateNumberArray = coordinateStringArray.map((coordinateString: any) => Number(coordinateString));

      const point = new Point(coordinateNumberArray);

      const coords = point.getCoordinates();
      let isNotANumber = false;

      coords.forEach((coord: any) => {
        if (isNaN(coord)) {
          isNotANumber = true;
        }
      })
      if (!isNotANumber && coords.length === 2) {
        // @ts-ignore

        /* console.log(coordinateNumberArray); */
        dispatch(setPanoramaCoordinate({ lat: coordinateNumberArray[1], lon: coordinateNumberArray[0] }))
        // point.transform('EPSG:4326', 'EPSG:3857');
        // map.getView().fit(point as SimpleGeometry, { duration: 500, maxZoom: 15 })
        setVisible(false)

        // dispatch(setFitGeom({ type: point.getType(), coordinates: point.getCoordinates() }));
      }
    }



    /*     if (coordinates && coordinates.length > 0) {
          const replacedCoordinateValue = coordinates.replace(/ /g, ',');
          const coordinateStringArray = replacedCoordinateValue.split(',');
          const coordinateNumberArray = coordinateStringArray.map((coordinateString: any) => Number(coordinateString));
          const point = new Point(coordinateNumberArray);
          console.log(coordinateNumberArray);
          dispatch(setPanoramaCoordinate({ lat: coordinateNumberArray[1], lon: coordinateNumberArray[0] }))
          // point.transform('EPSG:4326', 'EPSG:3857');
          // map.getView().fit(point as SimpleGeometry, { duration: 500, maxZoom: 15 })
          setVisible(false)
        } */
  }

  const goToCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  }

  const successCallback = (position: any) => {
    if (position) {
      const coord = position.coords
      dispatch(setPanoramaCoordinate({ lat: coord.latitude, lon: coord.longitude }))
      setVisible(false)
      // setTimeout(() => {
      //   const point = new Point([coord.longitude, coord.latitude]);
      //   point.transform('EPSG:4326', 'EPSG:3857');
      //   map.getView().fit(point as SimpleGeometry, { duration: 500, maxZoom: 19 })
      //   setVisible(false)
      // }, 200);
    }
  };

  const errorCallback = (error: any) => {
    console.log(error);
  };


  const handleCopyClick = () => {
    const textElement = document.querySelector('#coordinate');
    if (textElement) {
      const textToCopy = textElement.textContent || '';
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          toast.current?.show({ severity: 'success', detail: t("MESSAGE.Copied"), life: 1000 });

        })
        .catch((error) => {

        });
    }
  };




  return (
    <div className={`footer-content non-printable ${isPanoMiniScreen ? 'footer-content' : ''} ${isFullScreen && !isPanoMiniScreen ? 'footer-content2' : ''} ${tableVisibility && isFullScreen && !isPanoMiniScreen ? 'footer-content3' : ''} ${!tableVisibility && isFullScreen && !isPanoMiniScreen ? 'footer-content4' : ''}`}>
      {<div className="left" >
        <div className='label text'>
          {panoramaInfo.label}
        </div>
        <div className='label text' >
          {Moment(new Date(panoramaInfo.shootingDate)).format('DD-MM-YYYY hh:mm')}
        </div>
      </div>}
      <div className="right" >
        <div className='coordinate' onClick={handleCopyClick} style={{ cursor: 'pointer' }} >
          <span className='text'>{t('FOOTER.Coordinate')}:&nbsp;</span>
          <span className='text' id="coordinate" >

            {localStorage.getItem('setGoogleFormat') === 'true' ? <span>{pointerCoordinate[1] + ',' + pointerCoordinate[0]}</span> : <span>{pointerCoordinate[0] + ',' + pointerCoordinate[1]}</span>}&nbsp;
          </span>
        </div>
        <Dropdown filter value={selectedFormat} options={formats} onChange={onSelectedFormatChanged} optionLabel="coordinateFormat" className='epsg-list' />
        <Button
          tooltipOptions={{ position: 'top' }}
          tooltip={t('FOOTER.Go To Location')}

          className={visible ? 'go-to-location-active-button' : 'go-to-location-button'} onClick={() => setVisible(true)}>
          <img src="icons/go-to-location.svg" alt="" width="20px" height='20px' />
        </Button>
        <Dialog header={t('FOOTER.Go To Location')} visible={visible} onHide={() => setVisible(false)}>
          <div style={{ padding: '1rem 0' }}>
            {/* <InputText placeholder="26.43,43.26" onInput={(e: any) => setCoordinates(e.target.value)} /> */}

            <InputText
              placeholder="43.26,26.43"
              onInput={(e: any) => setCoordinates(e.target.value)}
              onKeyDown={(e: any) => {
                // console.log("e : ",e);
                // console.log("e keyCode: ",e.keyCode);
                if ((e.keyCode >= 65 && e.keyCode <= 90) || e.keyCode === 106 || e.keyCode === 107 || e.keyCode === 109 || e.keyCode === 111) {
                  console.log("zartt:", e);

                  e.preventDefault()
                }
              }}
            />


            <Button label={t('FOOTER.Go Location')} onClick={goLocation} style={{ width: '33px', height: '33px', color: 'white' }} />
            <Button label={t('FOOTER.Go Current Location')} onClick={goToCurrentLocation} style={{ width: '100px', height: '33px', color: 'white' }} />
          </div>
        </Dialog>
        <Toast ref={toast} position="bottom-right" style={{ zIndex: '9 !important' }} />
      </div>
    </div>
  )
}
