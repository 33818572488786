import { useContext, useEffect, useState } from 'react'
import { MapContext } from '../MapContext'
import Draw from 'ol/interaction/Draw';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { transform } from 'ol/proj';
import Text from 'ol/style/Text';
import { useDispatch } from 'react-redux';
import { setDrawState } from '../../../../../state/features/drawStateSlice';
import { DrawStateType } from '../../../../../util/model/drawState';
import WKT from 'ol/format/WKT';
import { setMapCoordinates } from '../../../../../state/features/coordinatesSlice';
import { setButtonState } from '../../../../../state/features/buttonStateSlice';
import { ButtonState } from '../../../../shared/button-state/ButtonState';

interface Props {
    source: any;
    layer: any;
}

export const DrawPoint = (props: Props) => {
    const map = useContext(MapContext);
    const dispatch = useDispatch();
    const [vector, setVector] = useState(null)

    useEffect(() => {
        let draw = new Draw({
            source: props.source,
            stopClick: true,
            type: 'Point',
            style: new Style({
                fill: new Fill({
                    color: '#64B5F6',
                }),
                stroke: new Stroke({
                    color: '#64B5F6',
                    width: 3,
                }),
                image: new CircleStyle({
                    radius: 5,
                    fill: new Fill({
                        color: '#3ea1f1',
                    }),
                }),
            }),
            condition: function (e: any) {
                switch (e.originalEvent.which) {
                    case 1:
                        return true;
                    case 3:
                        if(!((window as any).buttonState === ButtonState.ADD && (window as any).drawStartOfDigi)){
                            dispatch(setButtonState(ButtonState.NONE));
                        }
                        dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
                        map.removeInteraction(draw);
                        return false;
                    default:
                        return false;
                }
            }

        });
        setVector(props.layer)
        map.addInteraction(draw);

        draw.on('drawend', (event) => {
            setTextAfterDrawEnd(event)
            dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
        });

        return () => {
            map.removeInteraction(draw)
        }

    }, [map])


    /**
      * Çizim bittikten sonra ölçüm değerini çizilen nesnenin üzerine yazar. 
      */
    const setTextAfterDrawEnd = (event: any) => {
        const feature = event.feature;
        let coordinates: any = feature?.getGeometry().getCoordinates();
        coordinates = transform(coordinates, 'EPSG:3857', 'EPSG:4326');

        coordinates[0] = coordinates[0].toFixed(6)
        coordinates[1] = coordinates[1].toFixed(6)


        feature.setStyle(new Style({
            fill: new Fill({
                color: '#3ea1f1',
            }),
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: '#3ea1f1',
                }),
            }),
            text: new Text({
                text: `${coordinates}`,
                font: "bold 15px 'Open Sans', 'Arial Unicode MS', 'sans-serif'",
                placement: 'point',
                offsetY: -15,
                fill: new Fill({
                    color: 'white',
                }),
                stroke: new Stroke({
                    color: 'black',
                    width: 3,
                }),
            }),
        }));

        //aynı çizimi panorama üzerinde de göstermek için
        const wkt = new WKT();
        const wktFeature = wkt.writeFeature(feature)

        const result = {
            type: 'Point',
            feature: wktFeature,
            text: coordinates
        }

        dispatch(setMapCoordinates(result))
    }

    return (
        <>
        </>
    )
}
