import './App.scss';
import { Home } from './components/home/Home';
import { BrowserRouter as Router, Routes, Route, HashRouter, BrowserRouter, Navigate, useNavigate } from "react-router-dom";
import { Login } from './components/login/Login';
import { useState } from 'react';
import { useAppSelector } from './state/hooks';
import { useEffect } from 'react';
import { setLogOff } from './state/features/loginSlice';
import { useDispatch } from 'react-redux';

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'))
  const loginStatus = useAppSelector(state => state.login.loginStatus)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLogOff(true)); 
  }, []);
  return (
    <HashRouter>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="/*" element={<Home />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
