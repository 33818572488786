import { Tile } from 'ol/layer';
import { TileWMS } from 'ol/source';
import { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react'
import { MapContext } from '../MapContext'

interface IProps {
    url?: string;
    layername: any;
    serviceId: number;
    tag: string;
    priority?: number;
    visibleDomains: []
}

export const WmsLayer = (props: PropsWithChildren<IProps>) => {
    const map = useContext(MapContext)
    const t = useRef<Tile<any> | null>(null);
    const [cqlFilter, setCqlFilter] = useState<any>([])

    //  useEffect(() => {
    //     if(cqlFilter){
    //         console.log("cqlFilter :",cqlFilter);

    //     }
    // }, [cqlFilter]); 
    /**
     *  e.k. modified WmsLayer by adding useEffect
     */
    useEffect(() => {
        // console.log("REFRESH cqlFilter => ",cqlFilter);
        const tileLayer: any = new Tile({
            source: new TileWMS({
                params: {
                    layers: props.layername,
                    token: getToken(),
                    cql_filter: props.layername.map((layer:any)=>{
                        // console.log("cqlFilter :",cqlFilter);

                        const cqlFilterOfLayer :any = cqlFilter.filter((filter:any)=>filter.layername === layer)
                        //  console.log("cqlFilterOfLayer :",cqlFilterOfLayer);
                        // console.log("layer :",layer);
                        if(cqlFilterOfLayer.length){
                            return cqlFilterOfLayer.map((domain: any) => `${domain.filterTag}=${+domain.domainId}`).join(' OR ')
                        }else{
                            // console.log("1=1");

                            return `1=1`
                        }
                    }).join(';')

                    // `${cqlFilter && cqlFilter.length > 0 ? cqlFilter.map((domain: any) => `${domain.filterTag}=${+domain.domainId}`).join(' OR ') : `1=1`}`  // domaine göre filtrele
                },
                url: props.url,
                crossOrigin: "anonymous"
            }),
            zIndex: props.priority ? Number.MAX_SAFE_INTEGER - props.priority : 1
        })
        t.current = tileLayer;
    }, [cqlFilter.length, props.layername.length]);

    //domaine ve layerleri eşleştirme
    useEffect(() => {
        const filterByLayer: any = []
        // console.log("props. visibleDomains :",props.visibleDomains);
        // console.log("props.layername :",props.layername);
        props.visibleDomains.map((domain: any) => {
            props.layername.map((layername: any) => {
                if (domain.layername === layername) {
                    filterByLayer.push(domain)
                }
            })
        })
        // console.log("filterByLayer : ",filterByLayer);

        setCqlFilter([...filterByLayer])
    }, [props.visibleDomains, props.layername])


    useEffect(() => {
        const tileLayer = t.current!;
        tileLayer.set('tag', props.tag);
        tileLayer.set('serviceId', props.serviceId);
        tileLayer.setVisible(props.layername)
        tileLayer.getVisible()
        map.addLayer(tileLayer);
        return () => { map.removeLayer(tileLayer) }
    }, [props.tag, props.url, cqlFilter,props.layername])


    useEffect(() => {
        const tileLayer = t.current!;
        tileLayer.setVisible(props.layername);
        tileLayer.getSource().updateParams({
            layers: props.layername
        })
    }, [props.layername, props.serviceId])

    const getToken = () => {
        return localStorage.getItem('token')
    }

    return null;
}
