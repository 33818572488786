import './Print.scss';
import { Button } from 'primereact/button';
import { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';

import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { Project } from '../../util/model/project';
import { RootState } from '../../state/store';
import { setButtonState, setPrintButton, setSidebarButton } from '../../state/features/buttonStateSlice';
import { ButtonState } from '../shared/button-state/ButtonState';
import { setPrintPreviewMode, setShowPrint } from '../../state/features/printSlice';
import { useTranslation } from 'react-i18next';
import CheckBox from '../shared/custom/CheckBox';
import Moment from 'moment';

export const Print = ({selectedPrint}: any) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const toast = useRef<any>();
  const map = (window as any).olMap
  const [hasNote, setHasNote] = useState<boolean>(false);
  const [scale, setscale] = useState<any>('1 : 3,395,295')
  const [error, setError] = useState<number>(0);
  const projects: Project[] = useAppSelector((state: RootState) => state.layer.projects);
  const services: any = useAppSelector(state => state.layer.services);
  const printPreviewMode: boolean = useAppSelector(state => state.print.printPreviewMode);
  const [noteValue, setNoteValue] = useState<string>('')
  const panoramaInfo = useAppSelector(state => state.panorama.info)
  const [dateValue, setDateValue] = useState<boolean>(false)
  const [hasPanoramaLocation, setHasPanoramaLocation] = useState<boolean>(false)

  const [hasCompass, setHasCompass] = useState<boolean>(false);

  useEffect(() => {
    console.log("dispatch(setPrintPreviewMode(true))");
    dispatch(setSidebarButton(false))
    dispatch(setPrintPreviewMode(true))
  }, []);

  const closeWindow = async () => {
    // completed()
    document.getElementsByClassName("home")[0].classList.remove('both');
    document.getElementsByClassName("home")[0].classList.remove('only-panorama');
    document.getElementsByClassName("home")[0].classList.remove('only-map');
    document.getElementsByClassName("home")[0].classList.remove('print-start');
    viewChange("both")
    dispatch(setPrintPreviewMode(false))
    dispatch(setShowPrint(false)) 
    dispatch(setPrintButton(false))
    dispatch(setButtonState(ButtonState.NONE))
    dispatch(setSidebarButton(true))
  }
  const viewChange = (value: any) => {
    console.log("CHECK change : ",value);
    document.getElementsByClassName("home")[0].classList.remove('both');
    document.getElementsByClassName("home")[0].classList.remove('only-panorama');
    document.getElementsByClassName("home")[0].classList.remove('only-map');
    document.getElementsByClassName("home")[0].classList.add(value);
    selectedPrint(value);
  }
  const compassChange = (value: any) => {
    console.log("compassChange  : ",value);
    setHasCompass(value);
  }
  const prefixFiller = (value: string, prefixValue: string, expectedStringLen: number): string => {
    if (typeof value !== 'string') {
        throw new Error('value format is not expected');
    }
    let stringValue;
    if (prefixValue.length === 0) {
        throw new Error('length of prefixValue should not equals to 0');
    }
    if (prefixValue.length > 1) {
        throw new Error('length of prefixValue should be 1');
    }
    stringValue = value;

    const len = stringValue.length;
    if (len > expectedStringLen) {
        return stringValue;
    } else {
        let result = '';
        let i = 0;
        const count = expectedStringLen - value.length;
        while (i < count) {
            i++;
            result += prefixValue;
        }
        return result + stringValue;
    }
}

  const dateChange = (value: any) => {
    setDateValue(value)
    // if (dateValue !== '') {
    //   const date = new Date();
    //   const day = prefixFiller(date.getDate().toString(), '0', 2);
    //   const month = prefixFiller((date.getMonth() + 1).toString(), '0', 2);
    //   const year = prefixFiller((date.getFullYear()).toString(), '0', 4);
    //   const text = `${day}/${month}/${year}`;
    //   setDateValue(text);
    // } else {
    //   setDateValue('')
    // }
  }
  const panoramaInfoChange = (value: any) => {
    setHasPanoramaLocation(value)
   // setHasNote(!hasNote);
  }
  const noteChange = (value: any) => {
    setHasNote(/*!hasNote*/value);
  }

  const textValueChange = (value: any) =>{
    setNoteValue(value)
  }

  useEffect(() => {
    if (printPreviewMode) {
      /*document.body.classList.add('print-start');*/
      document.getElementsByClassName("home")[0].classList.add('print-start');
      // dispatch(setPrintPreviewMode(true))
    } else {
        /*document.body.classList.remove('print-start');*/
        document.getElementsByClassName("home")[0].classList.remove('print-start');
        // dispatch(setPrintPreviewMode(false))
    }
  }, [printPreviewMode]);

  const previewChange = () =>{
    if (!printPreviewMode) {
        // document.body.classList.add('print-start');
        dispatch(setPrintPreviewMode(true))
    } else {
        // document.body.classList.remove('print-start');
        dispatch(setPrintPreviewMode(false))
    }
    //this.noteValue = value;
  }
  
  const doneAfter = () => { 
    previewChange();
    viewChange("both")
    setTimeout(() => {
      dispatch(setPrintButton(false));
      dispatch(setButtonState(ButtonState.NONE));
    }, 100);
  } 
  (document as any).body.onafterprint = doneAfter;
  const startPrint = () =>{
    if (printPreviewMode) {
        // const element = document.getElementsByClassName('print-dialog')[0] as HTMLElement;
        const element2 = document.getElementsByClassName('p-dialog-mask')[0] as HTMLElement;
        // element.style.display = "none"
        element2.style.display = "none";
        (window as any).print()
    } else {
        previewChange()
        setTimeout(() => {
          const element2 = document.getElementsByClassName('p-dialog-mask')[0] as HTMLElement;
          element2.style.display = "none";
          (window as any).print()
        }, 500);
    }
  }
  return (
    <div className='Print' >
      <Toast ref={toast}></Toast>
      <Dialog
        className='print-dialog'
        header={t(`BUTTON.Print`)}
        visible={true}
        onHide={closeWindow}
      >
          <div className='pair'>
            <div className='item'>
              <fieldset className='fieldset'>
                <legend className='item-header'>{t(`BUTTON.GeneralView`)}</legend>
                <label>
                  {t(`BUTTON.PanoMap`)}
                    <input onChange={(e: any) => viewChange(e.target.value)}
                     type="radio" name="screen-type" value={"both"}
                     className="form-radio"/>
                </label>
                <label> 
                  {t(`BUTTON.OnlyMap`)}
                    <input onChange={(e: any) => viewChange(e.target.value)}
                     type="radio" name="screen-type" value={"only-map"}
                        className="form-radio"/>
                </label>
                <label>
                  {t(`BUTTON.OnlyPano`)}
                    <input onChange={(e: any) => viewChange(e.target.value)}
                     type="radio" name="screen-type"
                        value={"only-panorama"} className="form-radio"/>

                </label>
              </fieldset>
            </div>
            
            <div className="item">
              <fieldset className='fieldset2'>
                <legend className='item-header'>
                  {t(`BUTTON.InfoSelect`)}
                </legend>

                <CheckBox label={t(`BUTTON.NORTH`)} changeChecked={compassChange}/>
                <CheckBox label={t(`BUTTON.DATE`)} changeChecked={dateChange}/>
                <CheckBox label={t(`BUTTON.PANORAMALOCATION`)} changeChecked={panoramaInfoChange}/>
                <CheckBox label={t(`BUTTON.NOTE`)} changeChecked={noteChange}/>
               
               {hasNote && <textarea onChange={(e)=>textValueChange(e.target.value)}></textarea>}
                
              </fieldset>
            </div>

          <br></br>
     
          <div className="footer">

            {/* <CheckBox changeChecked={previewChange} label={t(`BUTTON.Preview`)}/> */}

            <Button
              disabled={!printPreviewMode}
              onClick={startPrint/*onClickPrint*/}
              label={t(`BUTTON.Print`)}
            ></Button>
          </div>
    
          <br></br>
          {/* <span
          style={{ display:error===2?'block':'none', color:'red' }}
          >
           Aynı isimde katman var. Lütfen farklı isimde dosya seçiniz
          </span>
          */}
        </div>
      </Dialog>

      { printPreviewMode && hasNote && noteValue && 
      <div className="kkn-info right-bottom only-printable">
         { hasNote && <div style={{whiteSpace:"pre"}} >{noteValue}</div>}
      </div>}
      {printPreviewMode && hasCompass && (
        <div className="right-top only-printable">
          <img src="icons/north-arrow.png"></img>
        </div>
      )}
      { printPreviewMode && 
      <div className="kkn-info only-printable">
         {  dateValue && 
              <div>
                 Date: {Moment(new Date(panoramaInfo.shootingDate)).format('DD-MM-YYYY hh:mm')}
              </div>
         }
         {
          (dateValue || hasPanoramaLocation) && <div style={{ paddingLeft: "4px", paddingRight: "4px"}}> | </div>
         }
         {  hasPanoramaLocation && 
              <div>
                 Panorama Konum: {panoramaInfo.label/*panoramaLocationValue*/}
              </div>
         }
      </div>}
    </div>
  );
}
