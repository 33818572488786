import { useContext, useEffect, useRef, useState } from 'react'
import Draw from 'ol/interaction/Draw';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { transform } from 'ol/proj';
import Text from 'ol/style/Text';
import { MapContext } from '../../../map-container/map/MapContext';
import { useDispatch } from 'react-redux';
import Snap from 'ol/interaction/Snap';
import { useAppSelector } from '../../../../../state/hooks';
import { DigiPopup } from '../digi-popup/DigiPopup';
import { setCancelDigiActivate, setDigiPopupVisibility, setCalculatedFields, setFrom } from '../../../../../state/features/digitizationSlice';
import VectorSource from 'ol/source/Vector';
import { DrawType, Mode, TransactWfs } from '../TransactWfs';
import { setButtonState } from '../../../../../state/features/buttonStateSlice';
import { ButtonState } from '../../../../shared/button-state/ButtonState';
import Feature from 'ol/Feature';
import calculateResult from './calculateResult';
import WKT from 'ol/format/WKT';
import { MultiPoint } from 'ol/geom';
import { GeomDataHolder } from '../../../panorama-container/easy-pano-imports';
import { setDrawState } from '../../../../../state/features/drawStateSlice';
import { DrawStateType } from '../../../../../util/model/drawState';
interface Props {
    source: VectorSource;
}
/* 
 * Bu componentle point geometri tipinde bir feature oluşrutuyoruz.
 *
*/
export const DigiDrawPoint = (props: Props) => {
    const map = useContext(MapContext);
    const dispatch = useDispatch();
    const [feature, setFeature] = useState<any>(undefined)
    const digiPopupVisibility = useAppSelector((state) => state.digitization.digiPopupVisibility)
    const transactStatus = useAppSelector(state => state.digitization.transactStatus)
    const cancelDigiActivate = useAppSelector((state) => state.digitization.cancelDigiActivate)
    const buttonState: any = useAppSelector(state => state.buttonState.buttonState)
    const from: string = useAppSelector(state => state.digitization.from)
    const digiPopupVisibilityStateRef = useRef(digiPopupVisibility);
    useEffect(() => {
        const vectorSource = props.source;
        const featureForParallel: any = vectorSource.getFeatures()[0]
        if (featureForParallel) {
            const feature = new Feature()
            feature.setGeometry(featureForParallel.values_.geometry)
            setFeature(feature)
            dispatch(setDigiPopupVisibility(true))
            dispatch(setFrom("Map"))
        }
    }, [])
    useEffect(() => {
        // console.log("digiPopupVisibility :",digiPopupVisibility);
        
        digiPopupVisibilityStateRef.current = digiPopupVisibility
        // console.log("digiPopupVisibilityStateRef.current :",digiPopupVisibilityStateRef.current);

        if(digiPopupVisibility){
            const intrcts = (window as any).olMap.getInteractions().getArray()
            intrcts.forEach((interaction: any) => {
                if (interaction instanceof Draw) {
                    map.removeInteraction(interaction)
                }
            });
        }
    }, [digiPopupVisibility]);

    useEffect(() => {

        let draw = new Draw({
            source: props.source,
            type: 'Point',
            style: new Style({
                fill: new Fill({
                    color: 'red',
                }),
                image: new CircleStyle({
                    radius: 5,
                    fill: new Fill({
                        color: 'red',
                    }),
                }),

            }),
            condition: function (e: any) {      
                switch (e.originalEvent.which) {
                    case 1:
                        return true;
                    case 3:
                        dispatch(setDigiPopupVisibility(false))
                        dispatch(setCancelDigiActivate(true))
                        dispatch(setButtonState(ButtonState.NONE));
                        dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
                        map.removeInteraction(draw);
                        return false;
                    default:
                        return false;
                }
            } 

        });
        // (window as any).olMap.getLayers().getArray()
        // .filter((layer2: any) => layer2.getVisible())
        // .map((layer: any) => {
        //     const wms: any = layer;
        //     const _source = wms.getSource()
        //     if (_source instanceof VectorSource) {
        //         const snap = new Snap({
        //             source: _source
        //         });
        //         map.addInteraction(snap);
        //     }
        // })
        map.addInteraction(draw);

        draw.on('drawend', async (event) => {
            map.removeInteraction(draw)
            setTextAfterDrawEnd(event)
            setFeature(event.feature)


            let geom;
            const wkt = new WKT();
            const _geomClone: any = event.feature.getGeometry()?.clone()
            let g: any = _geomClone.transform('EPSG:3857', 'EPSG:4326')
            geom = new MultiPoint([g.getCoordinates()]);
            const geomWktLine = wkt.writeGeometry(geom);
            geom.transform('EPSG:4326', 'EPSG:3857');
            const gdh: any = GeomDataHolder.fromWKT(geomWktLine);
            (window as any).panogl.getScalable().getMainSketchLayer().addGlobal(gdh);
            (window as any).panogl.getScalable().getMainSketchLayer().refresh();
            const calcFields = await calculateResult(event.feature);
            dispatch(setCalculatedFields(calcFields));
        });
        const content = map.getTargetElement();
        // content.addEventListener('contextmenu', (event: MouseEvent) => {
        //     map.removeInteraction(draw);
        // });
        // console.log("digiPopupVisibilityStateRef :",digiPopupVisibilityStateRef);
        
    
      /*       // Harita üzerinde nokta katmanında feature ekle aracıyla haritada sağ tıklayınca yeni nokta eklenmemesi için. Akabinde ise ilgili buton durumunu nokta durumuna geri getiriyor.
            map.getViewport().addEventListener('contextmenu', function (e) {
                if(!digiPopupVisibilityStateRef.current){
                    e.preventDefault(); // Sağ tıklamayı iptal et
                    dispatch(setDigiPopupVisibility(false))
                    dispatch(setCancelDigiActivate(true))
                    // dispatch(setFrom(""))

                    dispatch(setButtonState(ButtonState.NONE));
                    dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
                    map.removeInteraction(draw);
                }
         
            }, { once: true }); */
        return () => {
            map.removeInteraction(draw)
            props.source.clear();
        }
    }, [map])

    /**
      * Çizim bittikten sonra ölçüm değerini çizilen nesnenin üzerine yazar. 
      */
    const setTextAfterDrawEnd = (event: any) => {
        dispatch(setDigiPopupVisibility(true))
        dispatch(setFrom("Map"))
        let coordinates: any = event.feature?.getGeometry().getCoordinates();
        coordinates = transform(coordinates, 'EPSG:3857', 'EPSG:4326');

        coordinates[0] = coordinates[0].toFixed(6)
        coordinates[1] = coordinates[1].toFixed(6)
        event.feature.setStyle(new Style({
            fill: new Fill({
                color: 'red',
            }),
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: 'red',
                }),
            }),
            text: new Text({
                text: `${coordinates}`,
                font: "bold 15px 'Open Sans', 'Arial Unicode MS', 'sans-serif'",
                placement: 'point',
                offsetY: -15,
                fill: new Fill({
                    color: 'white',
                }),
                stroke: new Stroke({
                    color: 'black',
                    width: 3,
                }),
            }),
        }));
    }

    useEffect(() => {
        if (cancelDigiActivate) {
            props.source.clear();
            map.updateSize();
            setFeature(undefined)
            // dispatch(setCancelDigiActivate(false));
            dispatch(setButtonState(ButtonState.NONE))
            const layer_:any = map.getLayers().getArray().find((el:any)=> el.getProperties().alias === 'draws')
            const draws:any = [...layer_.getSource().getFeatures().filter((el:any)=>el.get('alias') !== 'draws')]
            if(draws.length){
                layer_.getSource().clear();
                layer_.getSource().addFeatures(draws)
            }else{
                layer_.getSource().clear();
            }
        }
    }, [cancelDigiActivate])

    return (
        <>
            {digiPopupVisibility && from !== 'Panorama' && <DigiPopup mode={Mode.INSERT} title='Add New Feature' from='Map' />}
            {feature && feature?.getGeometry() && transactStatus && (buttonState === ButtonState.ADD || ((window as any).buttonState === ButtonState.ADD && (window as any).drawStartOfDigi) || buttonState === ButtonState.PARALLEL) && <TransactWfs coordinates={feature.getGeometry().getCoordinates()} feature={feature} mode={Mode.INSERT} source={props.source} type={DrawType.POINT} />}
        </>
    )
}
